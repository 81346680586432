
$brand: #5CB1CA; 
$brand-dark: #4E9CAD;  
$text: #202C37; 
$grey: #E9EAF1; 

/*
==============================
Fonts
==============================
*/

$ff:   'Poppins', sans-serif; 
$ff-reg:   'Poppins-Regular', sans-serif; 
$ff-sem:   'Poppins-SemiBold', sans-serif;
$ff-bold:   'Poppins-ExtraBold', sans-serif;