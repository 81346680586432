@import "system/variables";
@import "system/mixin";
@import "system/reset";

html,
body {
  overflow-x: hidden;
  width: 100vw;

  @include respond-to(xs) {
    overflow-x: hidden;
    width: 100vw;
  }
}

@font-face {
  font-family: 'Poppins';
  src:
    url('/fonts/Poppins/Poppins-Medium.ttf') format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-Regular';
  src:
    url('/fonts/Poppins/Poppins-Regular.ttf') format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-SemiBold';
  src:
    url('/fonts/Poppins/Poppins-SemiBold.ttf') format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-ExtraBold';
  src:
    url('/fonts/Poppins/Poppins-ExtraBold.ttf') format("truetype");
  font-weight: normal;
  font-style: normal;
}


body {
  font-family: $ff;
  color: $text;
  line-height: 1.2;

  @include respond-to(xs) {
    font-size: 16px;
  }

  b,
  strong {
    font-family: $ff-bold;
  }
}

img {
  max-width: 100%;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.w-100 {
  width: 100%;
}

.site-width {
  max-width: 100%;
  @include vw($width: 1655,
    $padding: 0 15);
  //  width: 1655px;
  margin: 0 auto;

  //  padding: 0 15px;
  @include respond-to(lg) {}
}


.trigger{
  // margin-top: 5vh;
}
/******  HELPERS  ******/
.color-brand {
  color: $brand;
}

.color-white {
  color: #fff;
}

.text-center {
  text-align: center;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/******  LAYOUT  ******/
.title {
  // font-size: 80px;
  font-family: $ff-bold;
  line-height: 1.1;
  @include vw($font-size: 80);

  @include respond-to(xs) {
    font-size: 40px;
  }
}

.title-sm {
  @include vw($font-size: 34);
  color: $text;

  @include respond-to(xs) {
    font-size: 18px;
  }

  &--grey {
    color: #E9EAF1;
  }
}

.subtitle {
  font-family: $ff;
  line-height: 1.2;
  @include vw($font-size: 38);
}

.btn {
  text-decoration: none;
  @include vw($font-size: 38,
    $padding: 25 65,
    $border: 2 solid,
    $border-radius: 20,
    $min-width: 300,
  );
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.15), 0 10px 10px rgba(0, 0, 0, 0.12);
  border-color: #fff;
  ;
  background: #fff;
  color: $text;
  line-height: 1.2;
  transition: all 0.5s ease;

  @include respond-to(xs) {
    min-width: 130px;
    font-size: 21px;
    padding: 5px 10px;
    border-radius: 11px;
    border-color: transparent;
    display: inline-block;
    margin-bottom: 20px;
    padding: 16px 0;
    min-width: 230px;
    border-radius: 16px;
  }

  &:hover {
    background: $brand;
    color: #fff;
    text-decoration: none;
    box-shadow: 0 4px 18px rgba(0, 0, 0, 0.15), 0 0px 10px rgba(0, 0, 0, 0.12);
  }

}

.btn-sm {
  display: inline-block;
  text-decoration: none;
  font-family: $ff-sem;
  text-align: center;
  @include vw($font-size: 21,
    $padding: 7 65,
    $border: 2 solid,
    $border-radius: 8,
  );
  border-color: $brand;
  background: #fff;
  color: $brand;
  line-height: 1.2;
  transition: all 0.5s ease;


  &:hover {
    background: $brand;
    color: #fff;
    text-decoration: none;
    box-shadow: 0 4px 18px rgba(0, 0, 0, 0.15), 0 0px 10px rgba(0, 0, 0, 0.12);
  }


}

.btn {
  &-transp {
    border-color: #fff;
    color: #fff;
    background: transparent;

    @include respond-to(xs) {
      border-color: #fff;
    }

    &:hover,
    &:focus,
    &:active {
      color: $text;
      background: #fff;
    }
  }
}



.line {
  position: fixed;
  height: 100%;
  // width: 63px;

  background: $grey;
  width: 4px;
  @include vw($width: 4);
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  z-index: -1;

  // background-size: contain;
  &__bg {
    display: block;
    width: 100%;
    height: 50%;
    background: $brand;

    transition: height 5.5s ease;
  }
}

.circle {
  @include vw($width: 43,
    $height: 43);
  background: #fff url(/images/circle.png) center center no-repeat;
  background-size: 99%;
  position: absolute;
  transform: translateX(-50%);

  @include respond-to(xs) {
    width: 23px;
    height: 23px;
  }

  &-mask {
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    position: absolute;
    overflow: hidden;

    img {
      display: block;
      position: absolute;
      width: 99%;
      bottom: 0;
      left: 0;

    }
  }

  &-main {
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    position: absolute;
    overflow: hidden;

    img {
      display: block;
      position: absolute;
      width: 99%;
      bottom: 0;
      left: 0;

    }
  }

  // left: 50%;
  // bottom: 0;
  span {
    &:first-child {
      background: url('/images/circle.png') center center;
    }

  }

}

.scroll {

  @include vw($width: 46,
    $height: 88,
    $border-radius: 25,
    $box-shadow: inset 0 0 0 3 #000);
  bottom: 0;
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  z-index: 99;
  background: #fff;

  &__wrap {
    background: #fff;
    margin-top: 0;
    z-index: 98;
    text-align: center;
    flex-grow: 1;
    position: relative;
    margin: 0 auto;
    @include vw($width: 60);

    @include respond-to(xs) {
      display: none;

    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      height: 100%;
      background: $brand;
      @include vw($width: 4);
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      z-index: 9;
    }
  }

  &:before {
    position: absolute;
    content: '';
    // width: 4px;
    // height: 10px;
    background: $brand;
    // border: 1px solid $text;
    left: 50%;
    // margin-left: -2.5px;
    // top: 8px;
    // border-radius: 4px;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-name: scroll;
    @include vw($width: 4,
      $height: 10,
      $border: 1 solid $text,
      $margin-left: -2.5,
      $top: 8,
      $border-radius: 4,
    );
  }


  @keyframes scroll {
    0% {

      opacity: 1;
    }

    100% {

      opacity: 0;
      transform: translateY(46px);
    }
  }

  @include respond-to(xs) {
    display: none;
  }

}

.nav-side {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translate(100%, -50%);
  // max-height: 98vh;
  background: #fff;
  z-index: 999;
  box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.12);
  @include vw($width: 160,
    $max-height: 800,
    $border-top-left-radius: 15,
    $border-bottom-left-radius: 15,
    $padding: 0 20 30);
  text-align: center;
  transition: all 0.5s ease;
  // overflow-y: auto;
&.hide-icon{
  .nav-side__icon{
    display: none;
  }
}
  @include respond-to(xs) {
    // display: none;
    z-index: 9999999;
    width: 72px;
    padding: 0 5px 20px;
    height: auto;
  }

  &__icon {
    position: absolute;
    display: block;
    top: 50%;
    left: 0;
    transform: translate(-100%, -50%);
    background: $brand;
    ;
    cursor: pointer;
    z-index: 999;
    background: $brand url(/images/arrow-r.png) center center no-repeat;
    @include vw($width: 41,
      $height: 64,
      $border-top-left-radius: 50,
      $border-bottom-left-radius: 50,
      $background-size: 16 26);

    @include respond-to(xs) {
      width: 27px;
      height: 44px;
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;
      background-size: 9px 14px;
    }
  }

  &.is-active {
    transform: translate(0%, -50%);

    .nav-side__icon {
      background: $brand url(/images/arrow.png) center center no-repeat;
      @include vw($background-size: 16 26);

      @include respond-to(xs) {
        background-size: 9px 14px;
      }
    }
  }

  &.is-blocked {
    .nav-side__icon {
      background: $brand url(/images/arrow-r.png) center center no-repeat;
      @include vw($background-size: 16 26);

      @include respond-to(xs) {
        background-size: 9px 14px;
      }
    }

    transform: translate(100%, -50%);
  }

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__title {
    font-family: $ff-sem;
    @include vw($padding-top: 40,
      $font-size: 21,
      $margin-bottom: 15);

    @include respond-to(xs) {
      font-size: 12px;
      padding-top: 42px;
    }

    &.r-pad {
      @include vw($padding-top: 5);
    }
  }

  &__btn {
    transition: all 0.5s ease;
    cursor: pointer;
    @include vw($margin-bottom: 22);

    &-name {
      @include vw($font-size: 30,
        $margin-top: 3);
      color: $text;

      @include respond-to(xs) {
        font-size: 16px;
      }

    }

    &-icon {
      @include vw($width: 63,
        $height: 70);
      margin: 0 auto;

      @include respond-to(xs) {
        width: 25px;
        height: 28px;
      }
    }

    &:hover,
    &.is-active {
      .nav-side__btn-name {
        color: $brand;
      }
    }

    &.tile {
      .nav-side__btn-icon {
        background: url(/images/tile.png) center center no-repeat;

        background-size: contain;
      }

      &:hover,
      &.is-active {
        .nav-side__btn-icon {
          background: url(/images/tile-active.png) center center no-repeat;
          ;
          background-size: contain;
        }
      }
    }

    &.list {
      .nav-side__btn-icon {
        background: url(/images/list.png) center center no-repeat;
        ;
        background-size: contain;
      }

      &:hover,
      &.is-active {
        .nav-side__btn-icon {
          background: url(/images/list-active.png) center center no-repeat;
          ;
          background-size: contain;
        }
      }
    }
  }

  &__list {
    a {
      display: block;
      @include vw($font-size: 28,
        $margin-bottom: 7);
      transition: all 0.4s ease;

      @include respond-to(xs) {
        font-size: 16px;
      }

      &:hover {
        color: $brand;

      }

      &.is-active {
        color: $brand;
      }
    }
  }
}


.burger-icon {
  position: relative;
  /* top: 20px; */
  /* right: 30px; */
  // margin: 20px 7px 0;
  // width: 50px;
  // height: 40px;
  z-index: 99999;
  cursor: pointer;
  background-color: transparent;
  transition: all .5s ease;
  user-select: none;
  @include vw($margin: 20 7 0,
    $width: 50,
    $height: 40,
  );

  @include respond-to(xs) {
    margin: 4px 33px 0;
    width: 30px;
    height: 28px;
  }

  &__inner {
    width: 100%;
    height: 100%;
  }

  span {
    display: block;
    background-color: $brand;
    transition: .5s;
    overflow: hidden;
    // width: 48px;
    position: absolute;
    margin: auto;
    @include vw($height: 8,
      $border-radius: 59,
      $width: 48,
    );

    @include respond-to(xs) {
      height: 5px;
      border-radius: 3px;
      width: 30px;
    }

    &:nth-child(2) {
      top: 0;
      bottom: 0;
      margin: auto;
      opacity: 1;
    }

    &:nth-child(3) {
      bottom: 0;
    }
  }
}

.header {
  position: absolute;
  width: 100%;
  z-index: 9;

  @include respond-to(xs) {
    z-index: 9999;
  }

  .site-width {
    display: flex;
    justify-content: space-between;
    @include vw($padding: 43 0 43);

    @include respond-to(xs) {
      padding: 37px 0;

    }
  }

  .logo {
    // margin-left: 76px;
    // width: 305px;
    position: relative;
    @include vw($margin-left: 76,
      $width: 305);

    @include respond-to(xs) {
      margin-left: 30px;
      width: 155px;
    }

    img {
      &:nth-child(1) {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        ;
        opacity: 0;
        ;
      }
    }
  }
}
.trigger{
  // width: 10px;
  // height: 5px;
  // position: fixed;
  // background: red;
  // z-index: 9999999999;
  // top: 0;
  // height: 100%;
// display: none;
// height: 0;
  // &.is-show{
  //   height: 200vh;
  //   z-index: -999;
  //   // display: block;
  // // }
  // // position: absolute;top: 30px;
  // &.hide{
  //   height: 0;
//   height: 100vh;
//   overflow: scroll;
//   position: fixed;
//   top: 0;
// left: 0;
  &-scroll{
    // background:  red;
    // height: 200vh;
  }
  }
// }
.greet{
  // height: 100vh !important;
  //   overflow-y: scroll;
  //   &-scroll{
  //     height: 200vh;
  //   }
  // z-index: 0 !important;
  // &-scroll{
  //   height: 100vh;
  //   position: fixed;
  //   overflow: scroll;
  //   width: 20px;
  //   width: 100vw;
  //   background: red;
  //   z-index: 99;
  //   opacity: 0.4;
  // }
  // &-scroll3{
  //   height: 200vh;
  // }
  &-wrap{
    // position: fixed;
    position: relative;
    top: 0;
    height: 100vh;
    width: 100%;
  }

}
.content{
&.is-fixed{
  // position: fixed;
  // top: 0;
  // left: 0;
  // // overflow: hidden;
  // // height: 100%;
  // width: 100%;
}
}

.greet {

  // background: $brand url('/images/hero-bg.png') center center;
  // display: none;
  @include respond-to(xs) {
    // display: none;
    z-index: 9999999;
  }

  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
  min-height: 800px;
  @include vw($min-height: 800);
  z-index: 9999;

  &__bg {
    background: $brand url('/images/hero-bg.png') center center;
    background-size: cover;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
    position: absolute;

    @include respond-to(xs) {
      background: $brand url('/images/m-greet.png') center center;
    }
  }

  .site-width {
    display: flex;
    // width: 100%;
    height: 100%;
    justify-content: flex-start;
    position: relative;

    @include respond-to(xs) {
      flex-wrap: wrap;
      padding: 0 34px;
    }
  }

  &__left {
    flex-basis: 50%;
    /* display: flex; */
    width: 100%;

    @include respond-to(xs) {
      flex-basis: 100%;
    }
  }

  &__right {
    flex-basis: 50%;
    text-align: center;
    @include vw($padding-top: 680,
      $padding-left: 150);

    @include respond-to(xs) {
      flex-basis: 100%;
      padding: 0;
      margin: 0 auto;
    }

    .btn {
      @include respond-to(xs) {
        align-self: flex-end;
        display: inline-block;
        margin-bottom: 120px;
        padding: 16px 0;
        min-width: 230px;
        border-radius: 16px;
        transition: none;
      }
    }
  }

  .title {
    color: #fff;
    // padding-top: 464px;
    // margin-bottom: 9px;
    @include vw($padding-top: 464,
      $margin-bottom: 9);

    @include respond-to(xs) {
      padding-top: 140px;
      margin-bottom: 9px;
    }

  }

  .subtitle {
    color: $brand-dark;

    @include respond-to(xs) {
      font-size: 18px;
    }
  }
}

.hero {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  // position: relative;
  // min-height: 800px;
  @include vw($min-height: 800);
  position: relative;

  @include respond-to(xs) {
    height: auto;
    min-height: 0;
    background: #fff;
    z-index: 999;
    padding-bottom: 90px;

  }

  &__bg {
    background: url('/images/hero-bg-in.jpg') top center no-repeat;
    background-size: 100%;
    background-repeat: no-repeat;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 0;
    position: absolute;
    min-height: calc(100% + 20%);

    @include respond-to(xs) {
      background: url('/images/m-hero.png') top center no-repeat;
      background-size: 100%;
    }
  }

  .title {
    // opacity: 0;
    // transform: translateY(-5%);
  }

  .subtitle {

    // opacity: 0;
    // transform: translateY(-5%);
    @include respond-to(xs) {
      font-size: 18px;
      margin-top: 10px;

    }
  }

  .line {
    position: absolute;
    height: 100%;
    background: $grey;
    // background: $brand;
    // width: 4px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    z-index: 99;
    @include vw($width: 4);
    display: none;

    @include respond-to(xs) {
      position: relative;
      height: 146px;
      flex-grow: 1;
      order: 2;
      width: 23px;
      background: url(/images/m-arrow.png) center center no-repeat;
      background-size: contain;
      display: block;
    }

    &__bg {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;

      background: $brand;

      @include respond-to(xs) {
        display: none;
      }
    }

  }

  .circle {
    left: 50%;
    bottom: 0;

    @include respond-to(small) {
      display: none;

    }
  }

  .site-width {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    @include respond-to(xs) {
      align-items: flex-start;
      align-items: flex-start;
      flex-direction: column;
    }
  }

  &__content {

    position: relative;
    text-align: center;
    width: 100%;
    z-index: 99;
    margin-right: auto;
    margin-left: auto;
    flex-basis: 60%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    @include vw($padding: 50 0 0,
      $margin-top: 140,
      $margin-bottom: 0,
      $width: 780);

    @include respond-to(xs) {
      padding: 137px 0 67px;
      margin-top: 0px;
      margin-bottom: 0;
      width: auto;
      background: none;
      ;
    }
    .title{
      background: #fff;
      @include vw($padding-top: 40);
    }
    .subtitle{
      background: #fff;
      @include vw($padding-bottom: 80);
    }
  }
}

.time-line {
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  @include vw($padding-top: 105);
  display: none;
  // display: block;
  @include respond-to(xs) {
    padding-top: 0px;
    margin-top: -31px;
    // z-index: 999;
    position: relative;
  }

  &__content {
    position: relative;
    text-align: right;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include vw($padding: 35 47 15,
      $border-top-right-radius: 30,
      $border-bottom-right-radius: 30,
      $min-height: 180);
    transition: all 0.5s ease;

    @include respond-to(xs) {
      padding: 35px 15px 15px;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      // min-height: 180px;
      min-height: 70px;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      border-radius: 54%;
      // width: 80px;
      // height: 80px;
      background: #fff;
      transform: translateX(50%);
      @include vw($width: 80,
        $height: 80);

      @include respond-to(xs) {
        display: none;
      }
    }

    &.disable {
      .title-sm {
        color: #E9EAF1;
      }
    }

    &:not(.disable):hover {
      background: $brand;
      cursor: pointer;

      .text {
        color: #fff;
      }

      .btn-sm {
        color: #fff;
        border-color: #fff;
        background: $brand;

      }

      .title-sm {
        color: #fff;
      }
    }
  }

  &__row {
    width: 50%;
    position: relative;
    @include vw($padding-right: 8,
      $margin-top: 0,
      $margin-bottom: 20);
    margin-right: 1% !important;

    @include respond-to(xs) {
      // margin-right: 0% !important;
    }

    &.first {
      @include respond-to(xs) {
        display: none;
      }
    }

    &.last {
      .time-line__content {

        @include vw($min-height: 0,
          $padding: 0);
      }

      @include respond-to(xs) {
        margin-bottom: 12px;
      }
    }

    &.right {
      display: flex;
      width: 100%;
      flex-basis: 100%;
      margin-right: 0% !important;

      .time-line__content {
        flex-basis: 50%;
        flex-grow: 0;
        flex-shrink: 0;

      }

      .time-line__circle {
        @include vw($right: -47);

        @include respond-to(xs) {
          right: -28px;
        }
      }
    }

    &.info {
      // margin: 0px 0 190px;
      @include vw($margin: 0 0 190);

      &:last-child {
        @include vw($margin: 0 0 95);
      }

      @include respond-to(xs) {
        margin: 0 0 84px;
      }

    }


  }

  &__circle {
    // right: -50px;
    transform: translateX(-50%);
    background: none;
    background-size: 99%;
    z-index: 9;
    @include vw($right: -51);
    transition: all 0.5s ease;

    @include respond-to(xs) {
      right: -32px;
    }

    &.r-bg {

      // position: relative;
      &:before {
        @include respond-to(xs) {
          content: '';
          background: #fff;
          top: 0;
          left: 8%;
          height: 90px;
          width: 20px;
          position: absolute;
          transform: translateY(-100%);
        }
      }
    }
  }

  .title-sm {
    @include vw($margin-bottom: 10);
    transition: all 0.5s ease;
    font-family: $ff-bold;

    @include respond-to(xs) {
      margin-bottom: 5px;
    }
  }

  .text {
    @include vw($font-size: 21,
      $margin-bottom: 10);
    transition: all 0.5s ease;

    @include respond-to(xs) {
      font-size: 16px;
      margin-bottom: 0px;

    }
  }

  .btn-sm {
    min-width: 0;
    margin-left: auto;
    @include vw($width: 260);
    transition: all 0.5s ease;

    @include respond-to(xs) {
      font-size: 14px;
      width: 127px;
      padding: 7px 15px;
      border-radius: 5px;

    }
  }

  .line {
    @include respond-to(xs) {
      position: fixed;
      width: 2px;
      // background: red;
      top: 0;
    }
  }

  .additional {
    @include respond-to(xs) {

      padding: 30px;

      .additional__wrap {
        border: none;
        padding: 0;
        border-radius: 0;
        box-shadow: none;
      }

      .additional__dot {
        display: none;
      }

      .additional__inner {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }

      .additional__img {
        width: 60px;
      }

      .additional__content {
        padding-left: 0;
        font-size: 34px;
        width: auto;
        font-size: 18px;
      }
    }
  }
}

.cards {


  &__title {
    text-align: center;
    position: relative;
    @include vw($padding-top: 193);

    @include respond-to(xs) {
      padding-top: 100px;

    }

    .title-sm {
      position: relative;

      &:before {
        content: '';
        background: $brand;
        position: absolute;
        height: 100%;
        @include vw($width: 4,
          $height: 193,
          $top: -10);
        // top: 0;
        left: 50%;
        transform: translate(-50%, -100%);
        display: block;
        z-index: 9;

        @include respond-to(xs) {
          width: 2px;
          height: 85px;
          top: -10px;
        }
      }
    }
  }

  &__block {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;

    &.resize {
      @include vw($margin-bottom: 40);

      .card {
        @include vw($margin: 40 10 7);

        @include respond-to(xs) {
          border-radius: 10px;
          flex-basis: 150px;
          min-height: 213px;
          padding: 10px;
          margin: 5px;
        }
      }
    }

    .additional {
      @include vw($padding: 0,
        $flex-basis: 440,
        $margin-bottom: 40,
        $margin-top: 40,
        $min-height:540);

      @include respond-to(xs) {
        padding: 0;
        margin-bottom: 40px;
        margin-top: 40px;
        min-height: 260px;
        flex-basis: 180px;
      }

      &__wrap {
        @include vw($padding: 40);

        @include respond-to(xs) {
          padding: 15px;
        }
      }

      &__inner {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

      }

      &__img {
        @include vw($width: 200,
          $margin-bottom: 30);
        text-align: center;

        @include respond-to(xs) {
          width: 70px;
          margin-bottom: 10px;
        }
      }

      &__content {
        width: 100%;
        text-align: center;
        @include vw($padding: 0);

        @include respond-to(xs) {
          font-size: 16px;
        }
      }

    }
  }
}

.card {
  @include vw($min-height: 490,
    $flex-basis: 390,
    $margin: 45,
    $border-radius: 10,
    $padding: 55 20 25);
  background: #5CB1CA;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  @include respond-to(xs) {
    flex-basis: 150px;
    min-height: 213px;
    padding: 10px;
    margin: 5px 5px;
    ;
  }

  &.full {
    @include respond-to(xs) {
      margin-left: 40px;
      margin-right: 40px;
    }
  }

  &.bg-less {
    background: #fff;

    &:after {
      background: #41BAB9;

    }
  }

  &.bg-green {
    background: #7ECDCA;

    &:after {
      background: #41BAB9;

    }
  }

  &.bg-dark {
    background: #4E9CAD;

    &:after {
      background: #5CB1CA;

    }
  }

  .text {
    @include vw($font-size: 21,
      $padding-left: 15,
      $margin-bottom: 6);
    color: #fff;

    @include respond-to(xs) {
      font-size: 12px;
      padding-left: 0;
    }
  }

  .title-sm {
    margin-bottom: 5px;
    font-family: $ff-bold;

    @include respond-to(xs) {
      font-size: 14px;
    }
  }

  .desc {
    color: #fff;
    font-family: $ff-reg;
    @include vw($font-size: 18);

    @include respond-to(xs) {
      font-size: 10px;
    }
  }

  .btn-sm {
    @include vw($margin: auto auto 0);

    @include respond-to(xs) {
      font-size: 9px;
      width: 62px;
      padding: 5px 5px;
      border-width: 1px;
      border-radius: 5px;
    }
  }

}

.additional {
  flex-basis: 50%;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;

  @include vw($padding: 35 47 15,

  );

  &__wrap {
    @include vw($border: 2 solid $text,
      $padding: 20,
      $border-radius: 10,
    );
    box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.12);
    position: relative;

  }

  &__dot {
    @include vw($width: 10,
      $height: 10,
    );
    border-radius: 50%;
    position: absolute;
    background: $text;
    ;

    &:first-child {
      @include vw($top: 10,
        $left: 10);
    }

    &:nth-child(2) {
      @include vw($top: 10,
        $right: 10);

    }

    &:nth-child(3) {
      @include vw($bottom: 10,
        $left: 10);

    }

    &:nth-child(4) {
      @include vw($bottom: 10,
        $right: 10);
    }
  }

  &__inner {
    display: flex;
    align-items: center;

  }

  &__img {
    @include vw($width: 90);

  }

  &__content {
    @include vw($padding-left: 40,
      $font-size: 34,
      $width: 500);
    color: $text;
    max-width: 100%;
  }
}

.footer {
  background: #fff;
  height: 50vh;
  position: relative;
  @include vw($font-size: 34,
    $padding-top: 30);
  z-index: 9;
  font-family: $ff-bold;

  @include respond-to(xs) {
    font-size: 18px;
  }
}

.popup {
  position: fixed;
  top: 12%;
  width: 100%;
  background: #fff;
  background: #5cb1ca;
  @include vw($min-height: 840);
  z-index: 999;
  -webkit-mask-image: url(/images/mask-1.png);
  -webkit-mask-position: top center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: 100% 100%;
  -webkit-mask-clip: content-box;
  display: none;

  @include respond-to(xs) {
    -webkit-mask-image: url(/images/m-mask.png);
    -webkit-mask-position: top center;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-size: 100% 100%;
    -webkit-mask-clip: content-box;
    // display: none;
    min-height: 0;
    height: 100vh;
    top: 0;
    display: block;
    opacity: 0;
    bottom: 0;
    top: auto;
    transform: translateY(100%);
    transition: all 0.6s ease;

  }

  &.is-visible {
    @include respond-to(xs) {
      z-index: 999999999;
      opacity: 1;
      ;
      transform: translateY(0%);
    }
  }


  &:after {
    content: '';
    width: 100%;
    background: #4E9CAD;
    display: block;
    position: absolute;
    bottom: 0;
    @include vw($height: 120);

  }

  &.bg-green {
    background: #7ECDCA;

    &:after {
      background: #41BAB9;

    }
  }

  &.bg-dark {
    background: #4E9CAD;

    &:after {
      background: #5CB1CA;

    }
  }

  &__inner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include respond-to(xs) {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

    }
  }

  &__content {
    flex-grow: 0;
    flex-shrink: 0;
    @include vw($padding: 143 0 260 280,
    );
    max-width: 100%;
    flex-basis: 35.5%;

    @include respond-to(xs) {
      padding: 105px 30px;
      flex-basis: 100%;
      position: relative;
      z-index: 999999;
    }
  }

  .text {
    @include vw($font-size: 21,
      $margin-bottom: 5);
    color: #fff;

    @include respond-to(xs) {
      font-size: 16px;
    }

  }

  .title-sm {
    @include vw($margin-bottom: 15);
    font-family: $ff-bold;

    @include respond-to(xs) {
      font-size: 18px;
    }

    span {
      @include vw($font-size: 18,
        $margin-top: 7,
        $margin-bottom: 35);
      display: block;

    }
  }

  .descr {
    color: #fff;
    line-height: 1.35;
    @include vw($font-size: 21,
      $margin-bottom: 10);

    @include respond-to(xs) {
      font-size: 14px;
      line-height: 1.45;
    }
  }

  .btn-sm {
    @include vw($min-width: 260);

    @include respond-to(xs) {
      font-size: 14px;
      min-width: 118px;
      padding: 4px 10px;
      margin-top: 6px;
      border-radius: 5px;
    }
  }

  &__img {
    height: 100%;
    display: flex;
    align-items: flex-end;
    position: relative;
    z-index: 9;
    flex-basis: 52.7%;
    align-self: flex-end;
    flex-grow: 0;
    flex-shrink: 0;
    @include vw($padding-bottom: 50);

    @include respond-to(xs) {
      margin-top: auto;
      display: flex;
      height: auto;
      flex-basis: 100% !important;

      padding-bottom: 95px;
      position: absolute;
      padding-right: 0 !important;

    }

    img {
      width: 100%;
    }
  }

  &.q2-2013 {
    .popup__img {
      flex-basis: 56.7%;
      align-self: flex-end;
      @include vw($padding-bottom: 85,
        $padding-right: 217);

    }
  }

  &.q3-2013 {
    .popup__img {
      flex-basis: 42.4%;
      @include vw($padding-bottom: 20,
        $padding-right: 140);

      @include respond-to(xs) {
        padding-bottom: 0px;

      }
    }
  }

  &.q2-2016 {
    .popup__img {
      flex-basis: 50.5%;
      @include vw($padding-bottom: 5,
        $padding-right: 349);

      @include respond-to(xs) {
        width: 90%;
        margin-left: 5%;
        padding-bottom: 50px;
      }
    }
  }

  &.q3-2016 {
    .popup__img {
      flex-basis: 45.4%;
      @include vw($padding-bottom: 20,
        $padding-right: 214);

      @include respond-to(xs) {
        width: 90%;
        margin-left: 5%;
        padding-bottom: 50px;
      }
    }
  }

  &.q4-2016 {
    .popup__img {
      flex-basis: 59.6%;
      @include vw($padding-bottom: 55,
        $padding-right: 161);

      @include respond-to(xs) {
        padding-bottom: 115px;
      }
    }
  }

  &.q1-2018 {
    .popup__img {
      @include vw($padding-bottom: 115,
        $padding-right: 354);
      flex-basis: 49%;

      @include respond-to(xs) {
        padding-bottom: 75px;
      }
    }
  }

  &.q3-2018 {
    .popup__img {
      @include vw($padding-bottom: 0,
        $padding-right: 0,
        $margin-bottom: -25);
      flex-basis: 43.4%;
    }
  }

  &.q1-2019 {
    .popup__img {
      @include vw($padding-bottom: 80,
        $padding-right: 227);
      flex-basis: 52.1%;

      @include respond-to(xs) {
        padding-bottom: 0px;
      }
    }
  }

  &.q2-2019 {
    .popup__content {
      flex-basis: 36.5%;

      @include respond-to(xs) {
        flex-basis: 100%;
      }
    }

    .popup__img {
      @include vw($padding-bottom: 20,
        $padding-right: 140);
      flex-basis: 56.7%;

      @include respond-to(xs) {
        width: 150%;
        margin: auto;
        left: -25%;
      }
    }
  }

  &.q3-2019 {
    .popup__img {
      @include vw($padding-bottom: 70,
        $padding-right: 0);
      flex-basis: 57.4%;

      @include respond-to(xs) {
        padding-bottom: 0px;
        width: 160%;
        right: 0;
      }
    }

  }

  &.q3-1-2019 {
    .popup__img {
      @include vw($padding-bottom: 63,
        $padding-right: 0);
      flex-basis: 55.8%;

      .mob {
        display: none;
      }

      @include respond-to(xs) {
        .desk {
          display: none;


        }

        .mob {
          display: block;
        }

        padding-bottom: 0px;
      }
    }
  }

  &.q3-q4-2019 {
    .popup__img {
      @include vw($padding-bottom: 52,
        $padding-right: 0);
      flex-basis: 38.7%;

      @include respond-to(xs) {
        padding-bottom: 0;
      }
    }
  }

  &.q4-2019 {
    .popup__img {
      @include vw($padding-bottom: 72,
        $padding-right: 280);
      flex-basis: 50.2%;

      @include respond-to(xs) {
        padding-bottom: 0;
      }
    }
  }

  &.q4-1-2019 {
    .popup__img {
      @include vw($padding-bottom: 70,
        $padding-right: 218);
      flex-basis: 52.8%;
    }
  }

  &.q4-2-2019 {
    .popup__img {
      @include vw($padding-bottom: 46,
        $padding-right: 37);
      flex-basis: 62.8%;
    }
  }

  &.q1-2020 {
    .popup__img {
      @include vw($padding-bottom: 45,
        $padding-right: 150);
      flex-basis: 57.4%;

      @include respond-to(xs) {
        width: 150%;
        margin: auto;
        margin-left: -26%;
      }
    }
  }
}